import React, { useEffect, useState } from 'react'
import { displayTwoDigits, numbersAndDotOnly } from '../../utils/convert'
import { InputText } from 'primereact/inputtext'

interface RecoverCalculatorProps {
  cost: number
  recoverPercent: number
  handleRecoverChange: (value: string) => void
}

const RecoverCalculator = (props: RecoverCalculatorProps) => {
  const { cost, recoverPercent, handleRecoverChange } = props
  const [recover, setRecover] = useState<number>(cost)
  const [recoverPercentValue, setRecoverPercentValue] = useState<number | string>(displayTwoDigits(recoverPercent))

  const handleChange = (value: string) => {
    let myInput = numbersAndDotOnly(value)

    setRecoverPercentValue(myInput)
    calculateRecover(myInput, cost)
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '' || isNaN(Number(event.target.value))) {
      handleRecoverChange('0.00')
      setRecoverPercentValue('0.00')
      calculateRecover('0.00', cost)
    } else {
      setRecoverPercentValue(displayTwoDigits(event.target.value))
      handleRecoverChange(event.target.value)
    }
  }
  
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.currentTarget.blur()
    }
  }

  const calculateRecover = (myInput: number | string, cost: number) => {
    const calculatedRecover = cost * ((myInput && !isNaN(Number(myInput))) ? parseFloat(myInput.toString()) / 100 : 0)
    
    setRecover(calculatedRecover)
  }
 
  useEffect(() => {
    calculateRecover(recoverPercent, cost)
    setRecoverPercentValue(displayTwoDigits(recoverPercent))
  }, [cost, recoverPercent])

  return (
    <div className='recover-calculator'>
      <div>
        <h4>Recover estimation</h4>
        <div className='percent-input'>
          <InputText
            value={recoverPercentValue}
            onChange={(e) => handleChange(e.currentTarget.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyPress={onKeyPress} />
          <span>%</span>
        </div>
      </div>
      <div>
        <h4>Task Code Revenue</h4>
        <span>{`$ ${displayTwoDigits(recover)}`}</span>
      </div>
    </div>
  )
}

export default RecoverCalculator