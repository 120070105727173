import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ExecutiveSummary as ExecutiveSummaryState, IExecutiveSummary, JobsMenuItem } from "../../interfaces/Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import './ExecutiveSummaryHistory.css';
import { displayTwoDigits, parseFloatRemoveComma } from "../../utils/convert";
import { ExecutiveSummaryService } from "../../service/ExecutiveSummaryService";
import { setExecutiveSummary, updateExecutiveSummary } from "../../redux/appSlice";
import to from "await-to-js";
import { FullScreenLoadingIndicator } from "../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { CloseButton } from "../CloseButton/CloseButton";
import { getMonthYearForExecutiveSummary } from "../../utils/getMonthYearForExecutiveSummary";

export const ExecutiveSummaryHistory = (props:any) => {
	const [state, setState] = useState({ data: [] });

	const [loading, setLoading] = useState<Boolean>(false);
	const [disabled, setDisabled] = useState<Boolean>(true);
	const [first, setFirst] = useState(0);

	const dispatch = useDispatch();
	const accessToken = useSelector((state: RootState) => state.app.auth.token);
	const jobNumber = useSelector((state: RootState) => state.app.currentJobNumber);
	const jobDescription = useSelector((state: RootState) => state.app.currentJobDescription);
	const jobsMenuItemsIndex = useSelector((state: RootState) => state.app.jobsMenuItems.findIndex((JobMenuItem: JobsMenuItem) => JobMenuItem.JobNumber === jobNumber))
	const lastUpdated = useSelector((state: RootState) => state.app.lastUpdated);

	const executiveSummaryService = useMemo(() => new ExecutiveSummaryService(), []);

	const date = getMonthYearForExecutiveSummary();

	useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => {
		  document.body.removeAttribute('style')
		}
	}, []);

	const getSummaryData = useCallback(() => {
		const fetchData = async () => {
			setLoading(true);

			const [error, response] = await executiveSummaryService.getExecutiveSummary(jobNumber, parseInt(date.month), 2000);
			
			
			let data = response?.data
			
			let sortedDates = data.sort((p1:any, p2:any) => (new Date(p1.DateNow) > new Date(p2.DateNow)) ? -1 : (new Date(p1.DateNow) < new Date(p2.DateNow)) ? 1 : 0);

			if (data != null && error == null) {
				//@ts-ignore
				setState({ data: sortedDates })
				setLoading(false);
			} else {
				// if the data is undefined (there is no entry on Executive Summary table), then you don't have a row you can update
				setLoading(false);
				setDisabled(true);
			}
			
		}

		fetchData()
	}, [dispatch, accessToken, executiveSummaryService, jobNumber, '0'])

	useEffect(() => {
		// Make the API call to check if have any update
		getSummaryData();
		
	}, [getSummaryData, lastUpdated]);


	const dateNowParse = (rowData: any) => {
	
	let description = rowData.DateNow;
    let regex = /(T|Z|")/g;

    const replacer = (match:any) => {
    switch (match) {
      	case `Z`:
			return ` `;
		case `T`:
			return ` `;
		case `"`:
        	return ` `;
      	default:
        	return ` `;
        }
    };

    if (description.match(regex)) {
		let stringReplaced = description.replace(regex, replacer);
		let deleteMiliseconds = stringReplaced.split('.')[0];
		let dateParse = new Date(deleteMiliseconds).toLocaleString('en-US', { timeZone: 'PST' })
        return `${dateParse}`;
	} else {
      	return `${description}`;
	}
	}

	const totalProjectedTwoDigits = (rowData: any) => {
        return `$ ${displayTwoDigits(rowData.TotalProjectedRevenue)}`;
	}
	const totalProjectedCostTwoDigits = (rowData: any) => {
        return `$ ${displayTwoDigits(rowData.TotalProjectedCost)}`;
	}
	const marginTwoDigits = (rowData: any) => {
        return `$ ${displayTwoDigits(rowData.Margin)}`;
	}
	const marginPercentageTwoDigits = (rowData: any) => {
        return `${displayTwoDigits(rowData.MarginPercentage)} %`;
    }

	return (
		<>	
			{ loading && <FullScreenLoadingIndicator /> }
			<div id="executive_summary">
				<div className="top_bar">
					<div className="job_details">{jobNumber + "-" + jobDescription}</div>
					<div className="forecast_period">Forecast History</div>
					<CloseButton onClick={props.onClose} />
				</div>
				<div className="executive_summary_content">
					<div className="middle_info">
							<DataTable value={state.data} paginator rows={10} first={first} onPage={(e) => setFirst(e.first)}>
            					<Column field="DateNow" header="Date / Time Submitted" body={dateNowParse}></Column>
            					<Column field="TotalProjectedRevenue" header="Total Projected Revenue" body={totalProjectedTwoDigits}></Column>
            					<Column field="TotalProjectedCost" header="Total Projected Cost" body={totalProjectedCostTwoDigits}></Column>
								<Column field="Margin" header="Margin" body={marginTwoDigits}></Column>
								<Column field="MarginPercentage" header="Margin Percentage" body={marginPercentageTwoDigits}></Column>
								<Column field="Comments" header="Comments"></Column>
								<Column field="User" header="Submitter Name"></Column>
							</DataTable>
						</div>
				</div>
			</div>
		</>
	)
}