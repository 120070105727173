import cookie from 'js-cookie';

export const getTokenFromCookie = () => {
  return cookie.get("token") ?? null;
}

export const getIdTokenFromCookie = () => {
  return cookie.get("id_token") ?? null;
}

export const getRefreshTokenFromCookie = () => {
  return cookie.get("refresh_token") ?? null;
}

