import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './AppWrapper';
import {BrowserRouter, Router} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'prismjs/themes/prism-coy.css';
import { store } from "./redux/store";
import { Provider } from 'react-redux';
import { createBrowserHistory } from "history";
import { loadingContext, loadingContextValue } from "./components/Modal/LoadingContext/LoadingContext";
import { createModalContextValue, ModalContext } from "./components/Modal/ModalContext/ModalContext";
import { emitter } from "./components//Modal/emitter";
import { LoadingHub } from "./components/Modal/LoadingHub/LoadingHub";
import  ModalHub from "./components/Modal/ModalHub/ModalHub";

const browserHistory = createBrowserHistory();
const modalContextValue = createModalContextValue(emitter)


ReactDOM.render(
    <Router history={browserHistory}>
        <Provider store={store}>
            <ModalContext.Provider value={modalContextValue}>
                <loadingContext.Provider value={loadingContextValue}>
                    <BrowserRouter>
                        <ModalHub />
				        <LoadingHub />
                        <AppWrapper/>
                    </BrowserRouter>
                </loadingContext.Provider>
            </ModalContext.Provider>
        </Provider>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
