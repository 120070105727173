import dayjs from "dayjs";

// get current date and subtract 1 month or 1 year, by case
export const getMonthYearForExecutiveSummary = () => {
	const day = dayjs(new Date()).subtract(1, 'month').date(1).format("YYYY-MM-DD");
	const _month = dayjs(day).format("MM");
	let _year = dayjs(day).format("YYYY");

	return {
		year: _year,
		month: _month,
		monthString: dayjs(day).format("MMMM")
	}
}