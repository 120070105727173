import { Button } from "primereact/button";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { settings } from "../../config/settings";
import { logout } from "../../redux/appSlice";
import "./Logout.css";

export const LogOut: FC = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, [dispatch])

  const onSubmit = () => {
    // eslint-disable-next-line no-restricted-globals
    location.href = settings.redirectUri;
  }

  return (
    <div className="exception-body accessdenied-page logout-page">
      <div className="exception-type">
      </div>

      <div className="logout-block">
        <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="Graniterock"/>
        <h1>Logout successfully!</h1>
        <div className="exception-detail">If you want to access the application again you need to login .</div>
        <a href={settings.redirectUri} ><Button type="button" label="Login" onClick={onSubmit}></Button></a>
      </div>
    </div>
  );
}