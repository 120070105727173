import { BidItemInterface, TaskCodeInterface } from "../interfaces/Interfaces";

export const bidItemDictionary: BidItemInterface = {
  "BidItem": "Bid Item",
  "BidItemDisplay": "Bid Item",
  "ChgOrderDesc": "Chg. Order Desc.",
  "BidItemDescription": "Description",
  "UM": "UOM",
  "QTYBilled": "Bid QTY",
  "UnitPrice": "Bid UP",
  "Amount": "Bid Tot'l",
  "QtyAdjustment": "QTY Adj.",
  "JTDQty": "JTD Qty",
  "FinalQuantity": "Fin'l QTY",
  "FinalRevenue": "Fin'l Rev",
  "HasEditedFields": "N/A",
  "FinalCost": "Fin'l Cost",
  "RemCost" : "Rem Cost",
  "GainLoss": "G/L",
  "PreviousFinalCost": "Prev Final Cost",
  "Changes": "Delta",
  "BidComments" : "Comments"
};

export const taskCodeDictionary: TaskCodeInterface = {
  "Taskcode": "Task Code",
  "TaskcodeDescription": "Description",
  "UnitOfMeasure": "UOM",
  "BudgetUnits": "Budj. QTY",
  "BudgetUnitCost": "Budj. UC",
  "BudgetTotalCost": "Budj. Tot'l",
  "JTDUnits": "JTD Units",
  "JTDUnitCost": "JTD UC",
  "JTDTotalCost": "JTD Cost",
  "RemainingUnits": "Rem Units",
  "RemainingUnitCost": "Rem UC",
  "RemainingTotalCost": "Rem Cost",
  "FinalQuantity": "Fin'l QTY",
  "FinalUnitCost": "Fin'l UC",
  "FinalCost": "Fin'l Cost",
  "QtyAdjustment": "QTY Adj.",
  "RecoveryPercent": "Recovery Percent",
  "CostGL": "Cost G/L",
  "Changes": "Delta",
  "LastMonthFinalCost": "Prev Fin'l Cost",
  "Comments": "Comments",
  "CCOs": [],
  "HasEditedFields": "Edd",
};