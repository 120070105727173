const _settings = {
  production: {
    forecastManager: "https://rlm6ydea1e.execute-api.us-west-1.amazonaws.com/prod/",
    redirectUri: "https://myjobforecast.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=3nj5907j448vddajjd0o2j9n98&redirect_uri=https://www.myjobforecast.com/",
    logOutUri: "https://myjobforecast.auth.us-west-2.amazoncognito.com/logout?response_type=code&client_id=3nj5907j448vddajjd0o2j9n98&redirect_uri=https://www.myjobforecast.com/logout/",
    // authenticateUri: "",
    toastLife: 5000,
  },
  development: {
    forecastManager: "https://7vy0lbsyo7.execute-api.us-west-1.amazonaws.com/dev/",
    redirectUri: "https://myjobforecastdev.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=6ahmnvjh6do2t4aq3c13kqfmml&redirect_uri=https://dev.myjobforecast.com/",
    logOutUri: "https://myjobforecastdev.auth.us-west-2.amazoncognito.com/logout?response_type=code&client_id=6ahmnvjh6do2t4aq3c13kqfmml&redirect_uri=https://dev.myjobforecast.com/logout/",
    toastLife: 5000,
  },
  qa: {
    forecastManager: "https://gzf54a7mfb.execute-api.us-west-1.amazonaws.com/qa/",
    redirectUri: "https://myjobforecastqa.auth.us-west-1.amazoncognito.com/login?response_type=code&client_id=58uofdvkmb4f9vko69dp0g5app&redirect_uri=https://qa.myjobforecast.com/",
    logOutUri: "https://myjobforecastqa.auth.us-west-1.amazoncognito.com/logout?response_type=code&client_id=58uofdvkmb4f9vko69dp0g5app&redirect_uri=https://qa.myjobforecast.com/logout/",
    toastLife: 5000,
  },
  localhost: {
    forecastManager: "http://localhost:3000/localhost",
    redirectUri: "https://devmyjobforecast.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=usf4sra5p54ekqsakii3dk68b&redirect_uri=http://localhost:7777/",
    logOutUri: "https://devmyjobforecast.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=usf4sra5p54ekqsakii3dk68b&redirect_uri=http://localhost:7777/",
    toastLife: 5000,
  },
  localhostDev: {
    forecastManager: "https://7vy0lbsyo7.execute-api.us-west-1.amazonaws.com/dev/",
    redirectUri: "https://devmyjobforecast.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=usf4sra5p54ekqsakii3dk68b&redirect_uri=http://localhost:7777/",
    logOutUri: "https://devmyjobforecast.auth.us-west-2.amazoncognito.com/logout?response_type=code&client_id=usf4sra5p54ekqsakii3dk68b&redirect_uri=http://localhost:7777/",
    toastLife: 5000,
  }
};

export const stage = process.env.REACT_APP_STAGE ?? process.env.NODE_ENV ?? "development"
export const settings = _settings[stage as "production" | "development" | "qa"];
