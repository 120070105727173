import { useEffect, useState } from "react";
import qs from "qs";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios, { AxiosError } from "axios";
import to from "await-to-js";
import { settings } from "../config/settings";
import { addToken } from "../redux/appSlice";

export const useIdToken = () => {

  const [state, setState] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    const getToken = async () => {

      if (state === true) { return }
      const result = qs.parse(history?.location?.search?.replace("?", "") ?? "", { ignoreQueryPrefix: true });
      if (result.code == null || typeof result.code !== "string") { setState(true); return }

      let url = qs.parse(window.location.href.replace("?code", "") ?? "", { ignoreQueryPrefix: true })
      const parsedUrl = Object.entries(url).map(([key, value]) => { return (key) }).flat().toString()

      const [error, response] = await to(
        axios.post(
          settings?.forecastManager,
          {
            action: "Authenticate",
            data: {
              cognitoCode: result.code,
              url: parsedUrl
            }
          }
        )
      )

      if (error != null) { console.log("error: ", error as AxiosError); setState(true); return }

      dispatch(addToken({token: response?.data.access_token, id_token: response?.data.id_token, refresh_token: response?.data.refresh_token }))
      //setState(true);
    }
    getToken();

    // eslint-disable-next-line no-restricted-globals
  }, [dispatch, history, state])

  return (state);
}