import { EventEmitter } from "eventemitter3";
import React from "react";
import { v4 } from "uuid";

const emitter = new EventEmitter();

export type EmittersType = ReturnType<React.ContextType<typeof loadingContext>["getEmitters"]> | null | undefined;

export type ILoadingEventsByType = {
  [key: string]: IEmitterEventArgument
}

export type IErrorEventsByType = {
  [key: string]: IEmitterEventArgument & IErrorEventArgument;
}

export interface IErrorEventArgument {
  onDismiss?(): void;
  onRetry?(): void;
  message?: string | Error | React.ReactNode;
}

export interface IEmitterEventArgument {
  id: string;
  event: EmitterEvent;
  origin?: string;
}

export enum EmitterEvent {
  LOADING = "LOADING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  DISMISS_ERROR = "DISMISS_ERROR"
}

const emitLoadingEventFactory = (id: IEmitterEventArgument["id"], origin?: string) =>
  () => {
    const event = EmitterEvent.LOADING;
    emitter.emit(event, { id, event, origin } as IEmitterEventArgument);
  }

const emitCompletedEventFactory = (id: IEmitterEventArgument["id"], origin?: string) =>
  () => {
    const event = EmitterEvent.COMPLETED;
    emitter.emit(event, { id, event, origin } as IEmitterEventArgument);
  }

const emitErrorEventFactory = (id: IEmitterEventArgument["id"], origin?: string) =>
  (arg?: Partial<IErrorEventArgument & IEmitterEventArgument>) => {
    const event = EmitterEvent.ERROR;
    emitter.emit(event, { id, event, origin, ...arg } as IEmitterEventArgument & IErrorEventArgument);
  }

const emitDismissErrorEventFactory = (id: IEmitterEventArgument["id"], origin?: string) =>
  () => {
    const event = EmitterEvent.DISMISS_ERROR;
    emitter.emit(event, { id, event, origin } as IEmitterEventArgument);
  }

const getEmitters = (origin?: string) => {
  const id = v4();
  return ({
    emitLoadingEvent: emitLoadingEventFactory(id, origin),
    emitCompletedEvent: emitCompletedEventFactory(id, origin),
    emitErrorEvent: emitErrorEventFactory(id, origin),
    emitDismissErrorEvent: emitDismissErrorEventFactory(id, origin),
  });
}

export const loadingContextValue = {
  emitter,
  getEmitters,
}

export const loadingContext = React.createContext(loadingContextValue);
