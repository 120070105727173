import cookie from 'js-cookie';

export const removeTokenFromCookie = () => {
  cookie.remove('token');
};

export const removeIdTokenFromCookie = () => {
  cookie.remove('id_token');
};

export const removeRefreshTokenFromCookie = () => {
  cookie.remove('refresh_token');
};