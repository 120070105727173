import { parseFloatRemoveComma, toLocaleStringUSorZero } from "./convert"
import {
  BidItemInterface,
  BidItemToBeSaved,
  JobsMenuTaskCodeInterface,
  TaskCodeStructureFromDB,
  JobsMenuBidItemInterface,
  CCOInTaskCode
} from "../interfaces/Interfaces";
import {
  calculateBidItemChanges,
  calculateBidItemFinalCost,
  calculateBidItemFinalQuantity,
  calculateBidItemFinalRevenue,
  calculateBidItemGainLoss,
  calculateBidItemQtyAdjustment,
  calculateOriginalBidItemFinalRevenue,
  calculateBidItemPreviousFinalCost
} from "./bidItemCalculationsFunctions";
import { calculatedBidItemFinalRevenueHardcodeBidItem } from "../utils/bidItemCalculationsFunctions";
import { hardcodedBidItemsRCRP } from "./hardcodedBidItems";

export function setBidItemInitialValues(updatedBidItems: BidItemToBeSaved[], updatedTaskCodes?: TaskCodeStructureFromDB[], calculateFinalRevenue?: boolean) {

  for (let bidItem of updatedBidItems) {
    if (!bidItem.JTDQty) {
      bidItem.JTDQty = 0;
    }

    if (!bidItem.QtyAdjustment) {
      calculateBidItemQtyAdjustment(bidItem, false);
    }

    if (!bidItem.FinalQuantity) {
      calculateBidItemFinalQuantity(bidItem, false);
    }

    if (!bidItem.FinalRevenue) {
      if (bidItem.ChgNumber === "000") {
          calculateBidItemFinalRevenue(bidItem, updatedTaskCodes);
      }
    }
    calculateBidItemChanges(bidItem);
    calculateBidItemGainLoss(bidItem);
    calculateBidItemRemainingCost(bidItem, updatedTaskCodes)
    calculateBidItemPreviousFinalCost(updatedBidItems);
  }
}

//#region "Calculate Bid Item Field"
export function calculateBidItemFields(props: any, myInput: any): BidItemToBeSaved[] {
  let updatedBidItems = [...props.value];
  let currentBidItem = updatedBidItems[props.rowIndex];
  let currentEditedField = props.field;
  let originalInput = JSON.parse(JSON.stringify(myInput));  

  if (isNaN(parseFloat(myInput))) {
    currentBidItem[currentEditedField] = 0;
  } else if (myInput.slice(myInput.length - 1) === ".") {
    // If it ends with ".", meaning the user is in the middle of typing
    currentBidItem[currentEditedField] += "0";
  } else {
    currentBidItem[currentEditedField] = myInput;
  }

  switch (currentEditedField) {
    case "FinalQuantity":
      calculateBidItemQtyAdjustment(currentBidItem, true);
      calculateOriginalBidItemFinalRevenue(currentBidItem);
      calculateBidItemGainLoss(currentBidItem);
      calculateBidItemChanges(currentBidItem);
      break;
    case "QtyAdjustment":
      calculateBidItemFinalQuantity(currentBidItem, true);
      calculateOriginalBidItemFinalRevenue(currentBidItem);
      calculateBidItemGainLoss(currentBidItem);
      calculateBidItemChanges(currentBidItem);
      break;
    case "FinalRevenue":
      calculateBidItemGainLoss(currentBidItem);
      calculateBidItemChanges(currentBidItem);
      break;
    default:
      break;
  }  

  // Bring back the unaltered, possible incomplete value Eg: "-", "-0.", "12."
  currentBidItem[currentEditedField] = originalInput;

  return updatedBidItems;
}
//#endregion

export function calculateBidItemFinalRevenueField(
    bidItem: any, 
    taskCodes: TaskCodeStructureFromDB[] | JobsMenuTaskCodeInterface[], 
    calculate?: boolean,
    lastEditedValue?: number): BidItemToBeSaved {
  calculateBidItemFinalRevenue(bidItem, taskCodes, calculate, lastEditedValue);
  calculateBidItemGainLoss(bidItem);
  calculateBidItemChanges(bidItem);

  return bidItem;
}

export function calculateBidItemFinalCostField(bidItem: any, taskCodes?: any[]) {
calculateBidItemFinalCost(bidItem, taskCodes);
calculateBidItemGainLoss(bidItem);
calculateBidItemChanges(bidItem);  
  
return bidItem;
  
}

export function calculateBidItemDetailsFinalCostField(bidItems: any[]) {
  if (bidItems.length) {
    for (let i = 0; i < bidItems.length; i++) {
      if (bidItems[i].TaskCodeItems !== undefined && bidItems[i].ChgNumber === "000") {
        let bidItem = bidItems[i];

        calculateBidItemFinalCost(bidItem, bidItem.TaskCodeItems);
        calculateBidItemGainLoss(bidItem);
        calculateBidItemChanges(bidItem);
      }
    }

    return bidItems;
  }
}

export function prepareBidItemForDB(bidItem: BidItemInterface | JobsMenuBidItemInterface, jobNumber? : string, jobDescription? : string): BidItemToBeSaved {
  let bidItemToBeSaved = JSON.parse(JSON.stringify(bidItem));

  bidItemToBeSaved.Amount = parseFloatRemoveComma(bidItemToBeSaved.Amount);
  bidItemToBeSaved.FinalCost = parseFloatRemoveComma(bidItemToBeSaved.FinalCost);
  bidItemToBeSaved.QTYBilled = parseFloatRemoveComma(bidItemToBeSaved.QTYBilled);
  bidItemToBeSaved.QtyAdjustment = parseFloatRemoveComma(bidItemToBeSaved.QtyAdjustment) || 0;
  bidItemToBeSaved.UnitPrice = parseFloatRemoveComma(bidItemToBeSaved.UnitPrice);
  bidItemToBeSaved.FinalQuantity = parseFloatRemoveComma(bidItemToBeSaved.FinalQuantity) || 0;
  bidItemToBeSaved.FinalRevenue = parseFloatRemoveComma(bidItemToBeSaved.FinalRevenue) || 0;
  bidItemToBeSaved.GainLoss = parseFloatRemoveComma(bidItemToBeSaved.GainLoss);
  bidItemToBeSaved.JTDQty = parseFloatRemoveComma(bidItemToBeSaved.JTDQty) || 0;
  bidItemToBeSaved.PreviousFinalCost = parseFloatRemoveComma(bidItemToBeSaved.PreviousFinalCost);
  bidItemToBeSaved.Changes = parseFloatRemoveComma(bidItemToBeSaved.Changes);
  bidItemToBeSaved.RemCost = parseFloatRemoveComma(bidItemToBeSaved.RemCost);
  
  if (!bidItemToBeSaved.GLDate) {
    // removes the "T" and "Z" from the ISOString formatted date, to reflect the DB date format
    let newDate = new Date().toISOString().replace(/[T]/, ' ').slice(0, -1);

    bidItemToBeSaved.GLDate = bidItemToBeSaved.GLDate || newDate;
  }

  if (!bidItemToBeSaved.LastUpdated) {
    // removes the "T" and "Z" from the ISOString formatted date, to reflect the DB date format
    let newDate = new Date().toISOString().replace(/[T]/, ' ').slice(0, -1);
    
    bidItemToBeSaved.LastUpdated = bidItemToBeSaved.LastUpdated || newDate;
  }


  if (!bidItemToBeSaved.JobNumber) {
    bidItemToBeSaved.JobNumber = jobNumber ? jobNumber : "000";
  }

  if (!bidItemToBeSaved.JobDescription) {
    bidItemToBeSaved.JobDescription = jobDescription ? jobDescription : "";
  }

  if (!bidItemToBeSaved.UM) {
    bidItemToBeSaved.UM = "LS";
  }

  // delete bidItemToBeSaved.TaskCodeItems;
  console.log(bidItemToBeSaved)
  return bidItemToBeSaved;
}

export function setBidItemFinalRevenueHardcodeBidItems(updatedBidItems: BidItemToBeSaved[]) {
  for (let i = 0; i < updatedBidItems.length; i++) {
    if (hardcodedBidItemsRCRP.includes(updatedBidItems[i].BidItem)) {
      if (updatedBidItems[i].TaskCodeItems?.length) {
        updatedBidItems[i].FinalRevenue = calculatedBidItemFinalRevenueHardcodeBidItem(updatedBidItems[i].TaskCodeItems)
      }else{
        updatedBidItems[i].FinalRevenue = 0;
      }
    }
  }
  
  return updatedBidItems;
}

export function setBidItemGainLossHardcodeBidItems(updatedBidItems: BidItemToBeSaved[]){
  for (let i = 0; i < updatedBidItems.length; i++) {
    if (hardcodedBidItemsRCRP.includes(updatedBidItems[i].BidItem)) { 
      updatedBidItems[i].GainLoss = parseFloatRemoveComma(updatedBidItems[i].FinalRevenue) - parseFloatRemoveComma(updatedBidItems[i].FinalCost);
    }
  }

  return updatedBidItems
}

export function setBidItemFinalCostCCOs(updatedBidItems: JobsMenuBidItemInterface[], taskCodes: JobsMenuTaskCodeInterface[]) {
  for (let i = 0; i < updatedBidItems.length; i++){
    if (updatedBidItems[i].ChgNumber !== "000") {
      const currentCCOs = findBidItemCCOs(updatedBidItems[i], taskCodes);
      let FinalCost = 0;

      currentCCOs.forEach(cco => {
        FinalCost += Number(cco.AssignedCost)
      })
      
      updatedBidItems[i].FinalCost = FinalCost;
    }
  }
  return updatedBidItems
}

const findBidItemCCOs = (bidItem: JobsMenuBidItemInterface, taskCodes: JobsMenuTaskCodeInterface[]): CCOInTaskCode[] => {
  let currentCCOs: CCOInTaskCode[] = [];

  taskCodes.forEach(tc => {
    if (tc.CCOs?.length) {
      tc.CCOs.forEach(cco => {
        if (cco.ChgNumber === bidItem.ChgNumber) {
          currentCCOs.push(cco);
        }
      })
    }
  })

  return currentCCOs;
}

export const calculateBidItemRemainingCost = (bidItem: any, taskCodes?: any) => {
  
  let bidItemRemainingCost = 0;
  if (hardcodedBidItemsRCRP.includes(bidItem.BidItem) && bidItem?.TaskCodeItems?.length) {
    for (let i = 0; i < bidItem.TaskCodeItems.length; i++) {
      bidItemRemainingCost += bidItem.TaskCodeItems[i].RemainingTotalCost;
    }
    bidItem.RemCost = toLocaleStringUSorZero(bidItemRemainingCost)
  }else if (taskCodes) {
    for (let i = 0; i < taskCodes.length; i++) {
      if (taskCodes[i].BidItem === bidItem.BidItem) {
        bidItemRemainingCost += taskCodes[i].RemainingTotalCost
      }
      bidItem.RemCost = toLocaleStringUSorZero(bidItemRemainingCost)
    }
    bidItem.RemCost = bidItemRemainingCost
  } else {
    bidItem.RemCost = toLocaleStringUSorZero(bidItemRemainingCost)
  }
  return bidItem;
}

export const setBidItemPrevGLandDelta = (bidItems: any) => {
  for (let item in bidItems) {
    if (bidItems[item].BidItemDisplay) {
        let prevGL = 0;
        let delta = 0;
      if (bidItems[item].TaskCodeItems.length > 0) {
        for (let taskCode in bidItems[item].TaskCodeItems) {
          delta += bidItems[item].TaskCodeItems[taskCode].Changes;
          prevGL += bidItems[item].TaskCodeItems[taskCode].LastMonthFinalCost;
        }
      bidItems[item].Changes = delta;
      bidItems[item].PreviousFinalCost = prevGL;
      } else {
        bidItems[item].Changes = delta;
        bidItems[item].PreviousFinalCost = prevGL;
      }
    }
    bidItems[item].Changes = parseFloatRemoveComma(bidItems[item].FinalCost) - parseFloatRemoveComma(bidItems[item].PreviousFinalCost);
  }
  return bidItems;
  } 

