import { FC, useEffect, useState } from 'react';
import { useIdToken } from '../hooks/useIdToken';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Redirect, useHistory } from 'react-router-dom';
import { settings } from "../config/settings";
import { logout } from '../redux/appSlice';
import { LogOut } from '../pages/LogOut/LogOut';
import React from 'react';

export const Login: FC = props => {
	const [isLogOut, setIsLogOut] = useState(false);
	const hasId = useIdToken();
	const token = useSelector((state: RootState) => state.app.auth.id_token);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (!hasId) { return }
		if (token != null) { return }
		if (history?.location?.pathname === "/logout") {
			setIsLogOut(true);
			return
		}
		dispatch(logout());
		// eslint-disable-next-line no-restricted-globals
		location.href = settings.redirectUri;
	}, [token, hasId, history, dispatch]);
	if (token != null) { return <Redirect to="/" /> }
	if (isLogOut) { return <LogOut /> }

	return (null);
};
