// @ts-nocheck
// TODO: Once we know which components we use from the Template, update the code to typescript
import React from 'react';
import classNames from 'classnames';
import AppTopBar from './AppTopbar';
import { Route, Switch, withRouter } from 'react-router-dom';
import BidItems from "./components/BidItems";
import BidDetails from "./components/BidDetails";
import Welcome from "./components/Welcome";
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './ripple.js';
import './App.css';

import { AppComponentProps, AppComponentState} from "./interfaces/Interfaces";
import { bindActionCreators } from "redux";
import { setCurrentBidItemDisplay } from "./redux/appSlice";
import { connect } from "react-redux";
import { Menu } from "./components/Menu/Menu";
class App extends React.Component<AppComponentProps, AppComponentState> {

  private home = { icon: 'pi pi-home', url: "/" };

  constructor(props: AppComponentProps) {
    super(props);
    this.state = {
      menuClicked: false,
      layoutMode: 'static',
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      rotateMenuButton: false,
      darkMenu: false,
      menuActive: false,
      themeColor: 'indigo',
      itemsMenu: [],
      isDashboard: false,
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
  }

  componentDidUpdate(prevProps: AppComponentProps) {
    let itemsMenu = [...this.state.itemsMenu];
    if (prevProps.currentBidItemNumber !== this.props.currentBidItemNumber) {
      if(this.props.currentBidItemNumber !== "000") {
        let item = { ...itemsMenu[1] };
        item = { label: `${this.props.currentBidItemNumber}` };
        itemsMenu[1] = item;
        this.setState({ itemsMenu })
      }
    }

    if (prevProps.currentBidItemNumber !== this.props.currentBidItemNumber && this.props.currentBidItemNumber === "000") {
      itemsMenu.splice(1,1);
      this.setState({ itemsMenu })
    }

    if (prevProps.currentChangeNumber !== this.props.currentChangeNumber && this.props.currentChangeNumber !== "000") {
      let item = { ...itemsMenu[1] };
      item = { label: `${this.props.currentChangeNumberDesc}` };
      itemsMenu[1] = item;
      this.setState({ itemsMenu })
    }

    if (prevProps.currentChangeNumber !== this.props.currentChangeNumber && this.props.currentChangeNumber === "000") {
      itemsMenu.splice(1,1);
      this.setState({ itemsMenu })
    }
  }

  onMenuClick(event) {
    this.menuClick = true;
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.setState(({
      rotateMenuButton: !this.state.rotateMenuButton,
    }));

    this.setState({
      menuActive: !this.state.menuActive
    })

    if (this.state.layoutMode === 'overlay') {
      this.setState({
        overlayMenuActive: !this.state.overlayMenuActive
      });
    }
    else {
      if (this.isDesktop())
        this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
      else
        this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
    }
    event.preventDefault();
  }

  onRootMenuItemClick(event) {
    this.setState({
      menuActive: !this.state.menuActive
    });
    if (!event.item.items) {
      setTimeout(() => {
        this.createMenu();
        this.setState({ menuClicked: true });
      }, 0);
    }
  }

  onDocumentClick(event) {
    if (!this.menuClick) {
      if (this.isSlim()) {
        this.setState({ menuActive: false });
      }

      if (!this.isDesktop()) {
        this.setState({ menuActive: false });
      }
    }

    this.menuClick = false;
  }

  hideOverlayMenu() {
    this.setState({
      rotateMenuButton: false,
      overlayMenuActive: false,
      staticMenuMobileActive: false
    })
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isHorizontal() {
    return this.state.layoutMode === 'horizontal';
  }

  isSlim() {
    return this.state.layoutMode === 'slim';
  }

  render() {
    const layoutContainerClassName = classNames('layout-container', {
      'menu-layout-static': this.state.layoutMode !== 'overlay',
      'menu-layout-overlay': this.state.layoutMode === 'overlay',
      'layout-menu-overlay-active': this.state.overlayMenuActive,
      'menu-layout-slim': this.state.layoutMode === 'slim',
      'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
      'layout-menu-static-active': this.state.staticMenuMobileActive
    });
    const menuClassName = classNames('layout-menu', { 'layout-menu-dark': this.state.darkMenu });
    
    return (
      <>
        {/* <Growl ref={this.toastTR} /> */}
        <div className="layout-wrapper" onClick={this.onDocumentClick} style={{ height: "100%" }}>
          <div ref={(el) => this.layoutContainer = el} className={layoutContainerClassName}>
            <AppTopBar
              setLoadingExecutiveSummary={this.props.setLoadingExecutiveSummary}
              onMenuButtonClick={this.onMenuButtonClick}
              setLayoutMode={(layout) => { this.setState({ layoutMode: layout }) }}
              layoutMode={this.state.layoutMode}
              history={this.props.history}
              currentJobNumber={this.props.currentJobNumber}
              currentJobDescription={this.props.currentJobDescription}
              breadCrumbItems={this.state.itemsMenu}
            />
            <div className={menuClassName} onClick={this.onMenuClick}>
                <Menu location={this.props.location.search} setItemMenu={(menuItems)  => {this.setState({itemsMenu: menuItems})} }/>
            </div>

            <div className="layout-main">
              <div className="layout-content">
                <Switch>
                  <Route path="/" exact component={Welcome} />
                  <Route path="/forecast" component={Welcome} />
                  <Route path="/job" component={BidItems} />
                  <Route path="/bidDetails" component={BidDetails} />
                </Switch>
              </div>
            </div>

            <div className="layout-mask"></div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    currentBidItemNumber: state.app.setCurrentBidItemDisplay,
    changeJobInProgress: state.app.changeJobInProgress,
    currentJobNumber: state.app.currentJobNumber,
    currentJobDescription: state.app.currentJobDescription,
    currentChangeNumberDesc: state.app.currentChangeNumberDesc,
    currentChangeNumber: state.app.currentChangeNumber,
    setLoadingExecutiveSummary: state.app.setLoadingExecutiveSummary,
    taskcodesWithoutBidItem: state.app.taskcodesWithoutBidItem,
    taskcodesWithFlagsFC: state.app.taskcodesWithFlagsFC
  };
}

function matchDispatchToProps(dispatch: any) {
  return bindActionCreators({
    setCurrentBidItemDisplay: setCurrentBidItemDisplay,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(App));