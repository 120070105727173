import { parseFloatRemoveComma } from "./convert"
import { JobsMenuTaskCodeInterface, TaskCodeInterface, TaskCodeStructureFromDB } from "../interfaces/Interfaces";
import {
  calculateTaskCodeRemainingUnits,
  calculateTaskCodeRemainingUnitCost,
  calculateTaskCodeRemainingTotalCost,
  calculateTaskCodeFinalQuantity,
  calculateTaskCodeFinalUnitCost,
  calculateTaskCodeFinalCost,
  calculateTaskCodeQtyAdjustment,
  calculateTaskCodeRecoveryPercent,
  calculateDelta,
  calculateTaskCodeCostGL,
  calculateTaskCodeFinalQuantityInitialValues,
  calculateTaskCodeRemainingUnitsInitialValues,
  calculateTaskCodeRemainingUnitCostInitialValues,
  calculateTaskCodeFinalUnitCostInitialValues,
  calculateTaskCodeFinalCostInitialValues,
  calculateTaskCodeRemainingTotalCostInitialValues,
  calculateTaskCodeFinalQuantityFromJTD,
  calculateTaskCodeFinalUnitCostFromJTD,
  calculateTaskCodeFinalCostFromJTD,
} from "./taskCodeFieldsCalculationsFunctions";


export function setTaskCodeInitialValues(updatedTaskCodes: TaskCodeStructureFromDB[]) {

  for (let taskCode of updatedTaskCodes) {
    calculateTaskCodeFinalQuantityInitialValues(taskCode);
    calculateTaskCodeRemainingUnitsInitialValues(taskCode);
    calculateTaskCodeRemainingUnitCostInitialValues(taskCode);
    calculateTaskCodeRemainingTotalCostInitialValues(taskCode);
    calculateTaskCodeFinalUnitCostInitialValues(taskCode);
    calculateTaskCodeFinalCostInitialValues(taskCode);
    calculateTaskCodeQtyAdjustment(taskCode, true);
    calculateTaskCodeRecoveryPercent(taskCode);
    calculateDelta(taskCode);
    calculateTaskCodeCostGL(taskCode);
  }
}

export function calculateTaskCodeFields(props: any, myInput: any , inputInitialValue?: any, bidItemData?: any): TaskCodeStructureFromDB[] {
  let updatedTaskCodes = [...props.value];
  let currentTaskCode = updatedTaskCodes[props.rowIndex];
  let currentEditedField = props.field;
  let originalInput = JSON.parse(JSON.stringify(myInput));
  let chgOrderDesc = bidItemData?.ChgOrderDesc;
  
  if (isNaN(parseFloat(myInput))) {
    currentTaskCode[currentEditedField] = 0;
  } else if (myInput.slice(myInput.length - 1) === ".") {
    // If it ends with ".", meaning the user is in the middle of typing
    currentTaskCode[currentEditedField] += "0";
  } else {
    currentTaskCode[currentEditedField] = myInput;
  }
 
  switch (currentEditedField) {
    case "FinalQuantity":
      calculateTaskCodeFinalUnitCost(currentTaskCode, chgOrderDesc, false);
      calculateTaskCodeFinalCost(currentTaskCode, false, true, chgOrderDesc);
      calculateTaskCodeRemainingTotalCost(currentTaskCode, true);
      calculateTaskCodeRemainingUnits(currentTaskCode);
      calculateTaskCodeRemainingUnitCost(currentTaskCode);
      calculateTaskCodeQtyAdjustment(currentTaskCode);
      calculateDelta(currentTaskCode);
      calculateTaskCodeCostGL(currentTaskCode);
      break;
    case "FinalUnitCost":
      calculateTaskCodeFinalCost(currentTaskCode, false, true, chgOrderDesc);
      calculateTaskCodeRemainingTotalCost(currentTaskCode, true);
      calculateTaskCodeRemainingUnitCost(currentTaskCode);
      calculateDelta(currentTaskCode);
      calculateTaskCodeCostGL(currentTaskCode);
      break;
    case "FinalCost":
      calculateTaskCodeFinalUnitCost(currentTaskCode, chgOrderDesc, true);
      calculateTaskCodeRemainingTotalCost(currentTaskCode, true);
      calculateTaskCodeRemainingUnitCost(currentTaskCode);
      calculateDelta(currentTaskCode);
      calculateTaskCodeCostGL(currentTaskCode);
      break;
    case "RemainingUnits":
      calculateTaskCodeFinalQuantity(currentTaskCode, false, true);
      calculateTaskCodeFinalUnitCost(currentTaskCode, chgOrderDesc, false, true);
      calculateTaskCodeFinalCost(currentTaskCode, false, true);
      calculateTaskCodeRemainingTotalCost(currentTaskCode, true);
      calculateTaskCodeRemainingUnitCost(currentTaskCode);
      calculateTaskCodeQtyAdjustment(currentTaskCode);
      calculateDelta(currentTaskCode);
      calculateTaskCodeCostGL(currentTaskCode);
      break;
    case "RemainingUnitCost":
      calculateTaskCodeRemainingTotalCost(currentTaskCode, false);
      calculateTaskCodeFinalCost(currentTaskCode, true, false);
      calculateTaskCodeFinalUnitCost(currentTaskCode, chgOrderDesc, true);
      calculateDelta(currentTaskCode);
      calculateTaskCodeCostGL(currentTaskCode);
      break;
    case "RemainingTotalCost":
      calculateTaskCodeRemainingUnitCost(currentTaskCode);
      calculateTaskCodeFinalCost(currentTaskCode, true, false);
      calculateTaskCodeFinalUnitCost(currentTaskCode, chgOrderDesc, true);
      calculateDelta(currentTaskCode);
      calculateTaskCodeCostGL(currentTaskCode);
      break;
    case "QtyAdjustment":
      calculateTaskCodeFinalQuantity(currentTaskCode, true, false);
      calculateTaskCodeFinalUnitCost(currentTaskCode, chgOrderDesc, false);
      calculateTaskCodeFinalCost(currentTaskCode, false, true);
      calculateTaskCodeRemainingUnits(currentTaskCode);
      calculateTaskCodeRemainingTotalCost(currentTaskCode, true);
      calculateTaskCodeRemainingUnitCost(currentTaskCode);
      calculateDelta(currentTaskCode);
      calculateTaskCodeCostGL(currentTaskCode);
      break;
    case "RecoveryPercent":
      calculateTaskCodeRecoveryPercent(currentTaskCode, true);
      break;
    
    default:
      break;
  }  

  // Bring back the unaltered, possible incomplete value Eg: "-", "-0.", "12."
  currentTaskCode[currentEditedField] = originalInput;
  
  return updatedTaskCodes;
}


export function prepareTaskCodeForDB(taskCode: TaskCodeInterface | JobsMenuTaskCodeInterface) {
  let taskCodeToBeSaved = JSON.parse(JSON.stringify(taskCode)) as TaskCodeStructureFromDB;

  taskCodeToBeSaved.BudgetTotalCost = parseFloatRemoveComma(taskCodeToBeSaved.BudgetTotalCost);
  taskCodeToBeSaved.BudgetUnitCost = parseFloatRemoveComma(taskCodeToBeSaved.BudgetUnitCost);
  taskCodeToBeSaved.BudgetUnits = parseFloatRemoveComma(taskCodeToBeSaved.BudgetUnits);
  taskCodeToBeSaved.Changes = parseFloatRemoveComma(taskCodeToBeSaved.Changes);
  taskCodeToBeSaved.FinalCost = parseFloatRemoveComma(taskCodeToBeSaved.FinalCost);
  taskCodeToBeSaved.FinalQuantity = parseFloatRemoveComma(taskCodeToBeSaved.FinalQuantity);
  taskCodeToBeSaved.FinalUnitCost = parseFloatRemoveComma(taskCodeToBeSaved.FinalUnitCost);
  taskCodeToBeSaved.JTDTotalCost = parseFloatRemoveComma(taskCodeToBeSaved.JTDTotalCost);
  taskCodeToBeSaved.JTDUnits = parseFloatRemoveComma(taskCodeToBeSaved.JTDUnits);
  taskCodeToBeSaved.JTDUnitCost = parseFloatRemoveComma(taskCodeToBeSaved.JTDUnitCost);
  taskCodeToBeSaved.LastMonthFinalCost = parseFloatRemoveComma(taskCodeToBeSaved.LastMonthFinalCost);
  taskCodeToBeSaved.QtyAdjustment = parseFloatRemoveComma(taskCodeToBeSaved.QtyAdjustment);
  taskCodeToBeSaved.RecoveryPercent = parseFloatRemoveComma(taskCodeToBeSaved.RecoveryPercent);
  taskCodeToBeSaved.RemainingTotalCost = parseFloatRemoveComma(taskCodeToBeSaved.RemainingTotalCost);
  taskCodeToBeSaved.RemainingUnitCost = parseFloatRemoveComma(taskCodeToBeSaved.RemainingUnitCost);
  taskCodeToBeSaved.RemainingUnits = parseFloatRemoveComma(taskCodeToBeSaved.RemainingUnits);
  taskCodeToBeSaved.CostGL = parseFloatRemoveComma(taskCodeToBeSaved.CostGL);
  

  return taskCodeToBeSaved;
}

export function setCheckFinalCostValues(updatedTaskCodes: TaskCodeStructureFromDB[]) {

  for (let taskCode of updatedTaskCodes) {
    calculateTaskCodeFinalQuantityFromJTD(taskCode);
    calculateTaskCodeFinalUnitCostFromJTD(taskCode);
    calculateTaskCodeFinalCostFromJTD(taskCode);
    calculateTaskCodeRemainingUnitsInitialValues(taskCode);
    calculateTaskCodeRemainingUnitCostInitialValues(taskCode);
    calculateTaskCodeRemainingTotalCostInitialValues(taskCode);
    calculateTaskCodeQtyAdjustment(taskCode, true);
    calculateTaskCodeRecoveryPercent(taskCode);
    calculateDelta(taskCode);
    calculateTaskCodeCostGL(taskCode);
  }
}