import React, { FC, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { Modal, ModalContext } from "./Modal/ModalContext/ModalContext";

interface INavigationHandler {
	modals: Modal[];
	topModals: Modal[];
}

export const NavigationHandler:FC<INavigationHandler> = (props) => {
	const history = useHistory();
	const modal = useContext(ModalContext);

	useEffect(() => {
		history.block((tx) => {
			if(props.modals.length + props.topModals.length === 0) {
				return false;
			}

			modal?.closeAllModals();
			return true;
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return null;
}