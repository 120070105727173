import { AssignableTaskCode, BidItemInterface, JobsMenuBidItemInterface } from "../interfaces/Interfaces";
import { convertJobMenuBidItemToBidItem } from "./convert";
import * as BidItemCalculations from "./bidItemFieldsCalculations";

export function updateOriginalBidItems(jobBidItems: JobsMenuBidItemInterface[], taskCodes?: AssignableTaskCode[], bidItemId?: string): BidItemInterface[] | void {
  let bidItemIds: string[]
  let bidItemsToUpdate: JobsMenuBidItemInterface[]
  
  if (taskCodes) {
    bidItemIds = taskCodes.map(taskCode => taskCode.BidItem ? taskCode.BidItem : '')

    let uniqueBidItemIds = Array.from(new Set(bidItemIds))

    bidItemsToUpdate = findTaskCodesBidITems(jobBidItems, uniqueBidItemIds, bidItemId);
  } else {
    bidItemsToUpdate = findTaskCodesBidITems(jobBidItems, undefined, bidItemId);
  }

  let convertedBidItems: BidItemInterface[] = [];

  bidItemsToUpdate?.forEach(bidItem => {
    let currentBidItem = convertJobMenuBidItemToBidItem(bidItem);
    
    convertedBidItems = [...convertedBidItems, currentBidItem];
  })

  let updatedBidItems: BidItemInterface[] = calculateOriginalBidItems(convertedBidItems, bidItemsToUpdate);
  
  return updatedBidItems;
}

function calculateOriginalBidItems(bidItems: BidItemInterface[], bidItemsTaskCodes: JobsMenuBidItemInterface[]): any[] | [] {
  let calculatedBidItems: BidItemInterface[] | any = [];

  bidItems.forEach((bidItem, index: number) => {
    let currentTaskCodes = bidItemsTaskCodes[index].TaskCodeItems
    let currentBidItem = BidItemCalculations.calculateBidItemFinalRevenueField(bidItem, currentTaskCodes);
    currentBidItem = BidItemCalculations.calculateBidItemFinalCostField(currentBidItem, currentTaskCodes);
    calculatedBidItems = [...calculatedBidItems, {...currentBidItem, TaskCodeItems: [...currentTaskCodes]}];
  })

  return calculatedBidItems;
}

function findTaskCodesBidITems(jobBidItems: JobsMenuBidItemInterface[], bidItemsIds?: string[], bidItemId?: string): JobsMenuBidItemInterface[] {

  let currentBidItems = jobBidItems?.filter(bidItem => bidItemId 
    ? bidItem.BidItem === bidItemId 
    : bidItemsIds?.some(id => id === bidItem.BidItem))

  return currentBidItems;
}