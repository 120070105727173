import { CCOInTaskCode } from "../interfaces/Interfaces";
import { parseFloatRemoveComma } from "./convert";

export function calculateTaskCodeBudgetUnitCost(taskCode: any) {
  if (taskCode.BudgetUnits === 0) {
    taskCode.BudgetUnitCost = 0;
  } else {
    taskCode.BudgetUnitCost = taskCode.BudgetTotalCost / taskCode.BudgetUnits;
  }
}

export function calculateTaskCodeJTDUnitCost(taskCode: any) {
  if (taskCode.JTDUnits === 0) {
    taskCode.JTDUnitCost = 0;
  } else {
    taskCode.JTDUnitCost = taskCode.JTDTotalCost / taskCode.JTDUnits;
  }
}

export function calculateTaskCodeRemainingUnits(taskCode: any, isInitialValue?: boolean) {
  if (isInitialValue) {
    if (taskCode.BudgetUnits === 0 && taskCode.JTDUnits === 0 && taskCode.JTDTotalCost > 0) {
      taskCode.RemainingUnits = 1;
    } else if ((taskCode.BudgetUnits - taskCode.JTDUnits) < 0) {
      taskCode.RemainingUnits = 0;
    } else {
      taskCode.RemainingUnits = taskCode.BudgetUnits - taskCode.JTDUnits;
    }

    return;
  }

  taskCode.RemainingUnits = parseFloatRemoveComma(taskCode.FinalQuantity) - parseFloatRemoveComma(taskCode.JTDUnits);

  if (parseFloatRemoveComma(taskCode.RemainingUnits) === 0 && parseFloatRemoveComma(taskCode.RemainingTotalCost) === 0) {
     taskCode.RemainingUnits = 0
  }
}

export function calculateTaskCodeRemainingUnitCost(taskCode: any, isInitialValue?: boolean) {
  if (isInitialValue) {
    if (taskCode.RemainingUnits === 0) {
      taskCode.RemainingUnitCost = 0;
    } else if (taskCode.JTDUnitCost > 0) {
      taskCode.RemainingUnitCost = taskCode.JTDUnitCost;
    } else if ((taskCode.BudgetTotalCost - taskCode.JTDTotalCost) <= 0) {
        taskCode.RemainingUnitCost = 0;
    } else if ((taskCode.BudgetTotalCost - taskCode.JTDTotalCost) > 0) {
        taskCode.RemainingUnitCost = (taskCode.BudgetTotalCost - taskCode.JTDTotalCost) / taskCode.RemainingUnits;
    } else {
      taskCode.RemainingUnitCost = taskCode.BudgetUnitCost;
    }

    return;
  }

  if (parseFloatRemoveComma(taskCode.RemainingUnits) === 0) {
    taskCode.RemainingUnitCost = 0;
  } else {
    taskCode.RemainingUnitCost = parseFloatRemoveComma(taskCode.RemainingTotalCost) / parseFloatRemoveComma(taskCode.RemainingUnits);
  }
}

export function calculateTaskCodeRemainingTotalCost(taskCode: any, calculateByFinalCost?: boolean) {
  if (calculateByFinalCost) {
      taskCode.RemainingTotalCost = parseFloatRemoveComma(taskCode.FinalCost) - parseFloatRemoveComma(taskCode.JTDTotalCost);
    }  else {
    taskCode.RemainingTotalCost = parseFloatRemoveComma(taskCode.RemainingUnits) * parseFloatRemoveComma(taskCode.RemainingUnitCost);
  }
}

export function calculateTaskCodeFinalQuantity(taskCode: any, calculateByQtyAdjustment?: boolean, calculateByRemainingUnits?: boolean) {
  if (calculateByQtyAdjustment) {
    if (parseFloatRemoveComma(taskCode.BudgetUnits) === 0 && parseFloatRemoveComma(taskCode.JTDUnits) === 0){
      if(taskCode.BudgetTotalCost !== 0 || taskCode.JTDTotalCost !== 0){
        if(taskCode.QtyAdjustment === 0){
          taskCode.FinalQuantity = 1
        }
      } 
    } else if (parseFloatRemoveComma(taskCode.BudgetUnits) <= parseFloatRemoveComma(taskCode.JTDUnits)) {
      taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.JTDUnits) + parseFloatRemoveComma(taskCode.QtyAdjustment)
    } else {
      taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.BudgetUnits) + parseFloatRemoveComma(taskCode.QtyAdjustment)
    }
  } else if (calculateByRemainingUnits) {
    if (parseFloatRemoveComma(taskCode.JTDUnits) === 0 && parseFloatRemoveComma(taskCode.JTDTotalCost) !== 0 && parseFloatRemoveComma(taskCode.RemainingUnits) === 0) {
      taskCode.FinalQuantity = 1;
    } else {
      taskCode.FinalQuantity = parseFloatRemoveComma(taskCode.RemainingUnits) + parseFloatRemoveComma(taskCode.JTDUnits);  
    }    
  } else {
    if (taskCode.BudgetUnits === 0 && taskCode.JTDUnits === 0 && taskCode.JTDTotalCost > 0) {
      taskCode.FinalQuantity = 1;
    } else if (taskCode.BudgetUnits <= taskCode.JTDUnits) {
      taskCode.FinalQuantity = taskCode.JTDUnits;
    } else {
      taskCode.FinalQuantity = taskCode.BudgetUnits;
    }
  }  
}

export function calculateTaskCodeFinalUnitCost(taskCode: any, chgOrderDesc?: string, calculateByFinalCost?: boolean, calculateByRemQty?: boolean) {
  if (calculateByFinalCost) {
    if (taskCode.FinalQuantity === 0) {
      taskCode.FinalUnitCost = 0;
    } else {
      taskCode.FinalUnitCost = parseFloatRemoveComma(taskCode.FinalCost) / parseFloatRemoveComma(taskCode.FinalQuantity);
    }
  } else {
    if (parseFloatRemoveComma(taskCode.FinalQuantity) === 0) {
      taskCode.FinalUnitCost = 0;
    } else if(calculateByRemQty){
      if(taskCode.RemainingUnits === 0){
        taskCode.FinalUnitCost = taskCode.JTDTotalCost / taskCode.FinalQuantity;
      }
      else if(taskCode.BudgetUnits === 1 && taskCode.BudgetTotalCost === 0 && taskCode.JTDTotalCost < 0){
        taskCode.FinalUnitCost = taskCode.JTDTotalCost / taskCode.FinalQuantity;
      }
      else if(taskCode.BudgetUnits === 0 && taskCode.JTDUnits === 0){
        if(taskCode.BudgetTotalCost < taskCode.JTDTotalCost){
          taskCode.FinalUnitCost = taskCode.JTDTotalCost / taskCode.FinalQuantity 
        }else{
          taskCode.FinalUnitCost = taskCode.BudgetTotalCost / taskCode.FinalQuantity 
        }
      }
      else if(taskCode.JTDUnitCost > 0 || taskCode.BudgetUnits <= taskCode.JTDUnits){
        taskCode.FinalUnitCost = taskCode.JTDUnitCost
      } 
      else if(taskCode.BudgetTotalCost > taskCode.JTDTotalCost){
       if(taskCode.BudgetUnits === 0){
        taskCode.FinalUnitCost = taskCode.BudgetTotalCost / taskCode.FinalQuantity 
       }else{
        taskCode.FinalUnitCost = taskCode.BudgetUnitCost
       }
      }else{
        taskCode.JTDUnits === 0 
        ? taskCode.FinalUnitCost = taskCode.JTDTotalCost / taskCode.FinalQuantity 
        : taskCode.FinalUnitCost = taskCode.JTDUnitCost
        }

      } else {
      if(taskCode.FinalUnitCost !== 0){
        // eslint-disable-next-line no-self-assign
        taskCode.FinalUnitCost = taskCode.FinalUnitCost
      }else{
      if(taskCode.BudgetUnits === 1 && taskCode.BudgetTotalCost === 0 && taskCode.JTDTotalCost < 0){
        taskCode.FinalUnitCost = taskCode.JTDTotalCost / taskCode.FinalQuantity
      }
      else if (taskCode.BudgetUnits === 0 && taskCode.JTDUnits === 0) {
        if(taskCode.BudgetTotalCost < taskCode.JTDTotalCost){
          taskCode.FinalUnitCost = taskCode.JTDTotalCost / taskCode.FinalQuantity
        }else{
          taskCode.FinalUnitCost = taskCode.BudgetTotalCost / taskCode.FinalQuantity
        }    
      } 
      else if(taskCode.JTDUnitCost > 0 || taskCode.BudgetUnits <= taskCode.JTDUnits){
        taskCode.FinalUnitCost = taskCode.JTDUnitCost
      } 
      else if(taskCode.BudgetTotalCost > taskCode.JTDTotalCost){
        if(taskCode.BudgetUnits === 0){
          taskCode.FinalUnitCost = taskCode.BudgetTotalCost / taskCode.FinalQuantity
        }else{
          taskCode.FinalUnitCost = taskCode.BudgetUnitCost
        } 
      }else{
        if(taskCode.JTDUnits === 0){
          taskCode.FinalUnitCost = taskCode.JTDTotalCost / taskCode.FinalQuantity
        }else{
          taskCode.FinalUnitCost = taskCode.JTDUnitCost
        }
      }
    }
  }
}

  // TODO review this
  // if (taskCode.CCOs) {
  //   for (let CCOs of taskCode.CCOs) {
  //     if (CCOs.ChgOrderDesc === chgOrderDesc && CCOs.isTotalAllocated) {
  //       CCOs.AssignedCost = parseFloatRemoveComma(taskCode.FinalCost);
  //     }
  //   }
  // }
}

export function calculateTaskCodeFinalCost(taskCode: any, calculateByRemainingTotalCost: boolean, calculateByFinalUnitCost: boolean, isForUseInCCO?: boolean): number {
  if (calculateByFinalUnitCost) {
    taskCode.FinalCost = parseFloatRemoveComma(taskCode.FinalQuantity) * parseFloatRemoveComma(taskCode.FinalUnitCost);
  } else if (calculateByRemainingTotalCost) {
    taskCode.FinalCost = parseFloatRemoveComma(taskCode.RemainingTotalCost) + parseFloatRemoveComma(taskCode.JTDTotalCost);
  }

  if (taskCode.CCOs && !isForUseInCCO) {
    for (let CCOs of taskCode.CCOs) {
      if (CCOs.isTotalAllocated) {
        CCOs.AssignedCost = parseFloatRemoveComma(taskCode.FinalCost);
        taskCode.FinalCost = 0;
        break;
      } else {
        taskCode.FinalCost -= CCOs.AssignedCost;
      }
    }
  }
  return taskCode.FinalCost;
}

export function calculateDelta(taskCode: any): number{
  let finalCostCCOs = 0;
  if (taskCode.CCOs) {
    finalCostCCOs = parseFloatRemoveComma(taskCode.FinalQuantity) * parseFloatRemoveComma(taskCode.FinalUnitCost);
    return taskCode.Changes = parseFloatRemoveComma(taskCode.LastMonthFinalCost) - parseFloatRemoveComma(finalCostCCOs);
    }else{
    return taskCode.Changes = parseFloatRemoveComma(taskCode.LastMonthFinalCost) - parseFloatRemoveComma(taskCode.FinalCost);
  }
}

export function calculateTaskCodeFinalCostToNumber(taskCode: any): number {
  let updatedTaskCode = JSON.parse(JSON.stringify(taskCode));
  
  calculateTaskCodeFinalCost(updatedTaskCode, false, true)
  
  return parseFloatRemoveComma(updatedTaskCode.FinalCost)
}

export function calculateCCOsCost(ccos?: CCOInTaskCode[] | null) {
  if (!ccos || !ccos.length) {
    return 0;
  }

  return ccos.reduce((acc, cco) => acc + cco.AssignedCost, 0)
}

export function calculateTaskCodeQtyAdjustment(taskCode: any, isInitialValue?: boolean) {
  if (isInitialValue) {
    taskCode.QtyAdjustment = 0;

    return;
  }

  taskCode.QtyAdjustment = parseFloatRemoveComma(taskCode.FinalQuantity) - parseFloatRemoveComma(taskCode.BudgetUnits);
}

export function calculateTaskCodeRecoveryPercent(taskCode: any, calculateByRecoveryPercent?: boolean) {
  taskCode.RecoveryPercent = calculateByRecoveryPercent ? parseFloat(taskCode.RecoveryPercent) : 0;
}

export function calculateTaskCodeRemainingFinalCost(CCOs: any): number {
  let totalCCOsCost: number = 0
  
  for (let cco of CCOs) {
    totalCCOsCost += parseFloat(cco?.AssignedCost)
  }
  return totalCCOsCost
}

export function calculateTaskCodeCostGL(taskCode: any) {
  taskCode.CostGL = taskCode.BudgetTotalCost - taskCode.FinalCost
}

export function calculateTaskCodeRemainingUnitsInitialValues(taskCode: any) {
  if ((taskCode.BudgetUnits === 0 && taskCode.JTDUnits === 0) &&(taskCode.BudgetTotalCost !== 0 || taskCode.JTDTotalCost !== 0)) {
    taskCode.RemainingUnits = 1;
  } else if (taskCode.BudgetUnits < taskCode.JTDUnits) {
    taskCode.RemainingUnits = 0;
  } else {
    taskCode.RemainingUnits = taskCode.BudgetUnits - taskCode.JTDUnits;
  }
}

export function calculateTaskCodeRemainingUnitCostInitialValues(taskCode: any) {
  if (taskCode.RemainingUnits === 0) {
    taskCode.RemainingUnitCost = 0;
  } else if (taskCode.JTDUnitCost > 0) {
    taskCode.RemainingUnitCost = taskCode.JTDUnitCost;
  } else if (
    (taskCode.BudgetUnits === 1 && taskCode.BudgetTotalCost === 0 && taskCode.JTDTotalCost < 0) 
    || (taskCode.BudgetTotalCost < 0 && taskCode.JTDTotalCost === 0)
  ) {
      taskCode.RemainingUnitCost = 0;
  } else if (taskCode.JTDUnits === 0) {
      if(taskCode.BudgetTotalCost < taskCode.JTDTotalCost){
        taskCode.RemainingUnitCost = 0;
      }else{
        taskCode.RemainingUnitCost = (taskCode.BudgetTotalCost - taskCode.JTDTotalCost) / taskCode.RemainingUnits;
      }
  } else {
    taskCode.RemainingUnitCost = taskCode.BudgetUnitCost;
  }
}

export function calculateTaskCodeFinalCostInitialValues(taskCode: any) {
  taskCode.FinalCost =  taskCode.FinalQuantity === 0 || taskCode.FinalUnitCost === 0 ? 0 : taskCode.FinalQuantity * taskCode.FinalUnitCost;
  
  // Reset assigments of CCOs 
  if (taskCode.CCOs) {
    taskCode.CCOs = null
  }
}

export function calculateTaskCodeRemainingTotalCostInitialValues(taskCode: any) {
    taskCode.RemainingTotalCost = taskCode.RemainingUnits * taskCode.RemainingUnitCost;
}

export function calculateTaskCodeFinalQuantityInitialValues(taskCode: any) {
  if ((taskCode.BudgetUnits === 0 && taskCode.JTDUnits === 0) &&(taskCode.BudgetTotalCost !== 0 || taskCode.JTDTotalCost !== 0)) {
    taskCode.FinalQuantity = 1;
  } else if (taskCode.BudgetUnits <= taskCode.JTDUnits) {
    taskCode.FinalQuantity = taskCode.JTDUnits;
  } else {
    taskCode.FinalQuantity = taskCode.BudgetUnits;
  }
}

export function calculateTaskCodeFinalUnitCostInitialValues(taskCode: any) {
  if (taskCode.FinalQuantity === 0) {
    taskCode.FinalUnitCost = 0;
  } else if (taskCode.BudgetUnits === 1 && taskCode.BudgetTotalCost === 0 && taskCode.JTDTotalCost < 0){
    taskCode.FinalUnitCost = taskCode.JTDTotalCost / taskCode.FinalQuantity;
  } else if (taskCode.BudgetUnits === 0 && taskCode.JTDUnits === 0) {
    if (taskCode.BudgetTotalCost > taskCode.JTDTotalCost) {
      taskCode.FinalUnitCost = taskCode.BudgetTotalCost;
    } else {
      taskCode.FinalUnitCost = taskCode.JTDTotalCost;
    }
  } else if (taskCode.JTDUnitCost > 0 || taskCode.JTDUnits >= taskCode.BudgetUnits) {
    taskCode.FinalUnitCost = taskCode.JTDUnitCost;
  } else {
    if (taskCode.BudgetTotalCost > taskCode.JTDTotalCost) {
      taskCode.FinalUnitCost = taskCode.BudgetTotalCost / taskCode.FinalQuantity;
    } else {
      taskCode.FinalUnitCost = taskCode.JTDTotalCost / taskCode.FinalQuantity;
    }
  }
}

export function calculateTaskCodeFinalQuantityFromJTD(taskCode: any) {
  taskCode.FinalQuantity = taskCode.JTDUnits;
}

export function calculateTaskCodeFinalUnitCostFromJTD(taskCode: any) {
  taskCode.FinalUnitCost = taskCode.JTDUnitCost;
}
export function calculateTaskCodeFinalCostFromJTD(taskCode: any) {
  taskCode.FinalCost = taskCode.JTDTotalCost;
}