// @ts-nocheck
import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import App from "./App";
import { Login } from "./pages/Login";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { LogOut } from './pages/LogOut/LogOut';


const AppWrapper = (props) => {

	const redirectCase = (token: any) => {
		if (token == null) {
			return <Route exact component={Login} />
		}
		else {
			switch (props.location.pathname) {
				case "/login":
					return <Route exact component={Login} />;
				case "/logout":
					return <Route path="/logout" component={LogOut} />;
				case "/job":
				case "/forecast":
				case "/bidDetails":
					return <App />;
				case "/":
					if (props.location.search) {
						return <Redirect to="/" />
					}
					return <App />;
				default:
					return <Redirect to="/" />;
			}
		}
	};

	const token = useSelector((state: RootState) => state.app.auth.id_token);
	return (
		redirectCase(token)
	)
};

export default withRouter(AppWrapper);
