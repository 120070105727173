import * as React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import AWS from "aws-sdk";
import { AxiosError } from 'axios';
import { RootState } from "../redux/store";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Growl } from 'primereact/growl';
import { Checkbox } from 'primereact/checkbox';
import {
  setCurrentBidItemNumber,
  setCurrentJobNumberAndDescription,
  setCurrentChangeNumber,
  setCurrentBidItemDisplay,
  setJobsMenuBidItemsAndTaskCodes,
  bidItemSaved,
  maintainAllLinkedTaskCodesToBidItems,
  setChangeJobInProgress,
  setExecutiveSummary,
  setCurrentBidItems,
  setResetJobInProgress,
  setLoadingExecutiveSummary,
  setPrevGLandDeltaCCOs,
  setUsername,
  setTaskcodesWithoutBidItem,
  setTaskcodesWithFlagsFC
} from "../redux/appSlice";
import { BidItemInterface, BidItemsProps, BidItemsState, BidItemToBeSaved } from "../interfaces/Interfaces";
import { bidItemDictionary } from "../utils/dictionaries";
import { BidItemsService } from "../service/BidItemsService";
import { JobDataService } from "../service/JobDataService";
import { ExecutiveSummaryService } from "../service/ExecutiveSummaryService";
import { displayTwoDigits, numbersAndDotOnly, parseFloatRemoveComma, toLocaleStringUS } from "../utils/convert";
import * as BidItemCalculations from "../utils/bidItemFieldsCalculations";
import { getMonthYearForExecutiveSummary } from '../utils/getMonthYearForExecutiveSummary';
import { getParams } from "../utils/useQueryParams";
import { hardcodedBidItems, hardcodedBidItemsRCRP } from "../utils/hardcodedBidItems";
import { exportBidItemsAndTaskCodes } from "../utils/excelSheetsExport";
import 'primeicons/primeicons.css';
import '../assets/sass/BidItems.scss';

class BidItems extends React.Component<BidItemsProps, BidItemsState> {
  private mountedComponent: boolean | undefined;
  private bidItemsService: BidItemsService;
  private jobDataService: JobDataService;
  private executiveSummaryService: ExecutiveSummaryService;
  private toastTR: React.RefObject<Growl> = React.createRef();
  private handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.state.inputInitialValue === '') {
      this.setState({ inputInitialValue: event.target.value });
    }
    event.target.select()
  };

  readonly bidItemStructure: BidItemInterface;
  private IsThisPCItem = (rowData: any) => {
    if (rowData.ChgNumber !== "000") {
      return true
    }
  };
  private IsThisHardcodedBidItem = (rowData: any) => {
    const _hardcodedBidItems = hardcodedBidItems.map(bid => bid.BidItem);
    return _hardcodedBidItems.includes(rowData.BidItem);
  };
  private IsThisHardcodedBidItemAllowFinalRevenueEdit = (rowData: any) => {
    // Return the BidItems numbers which DON@T have a numeric value on Final Revenue field (it means that the user is NOT able to edit them). Current: 888, 950
    return hardcodedBidItemsRCRP.includes(rowData.BidItem);
  };

  private rowClass = (rowData: any) => {
    return {
      'background-color-pink': this.IsThisPCItem(rowData)
    }
  };

  constructor(props: BidItemsProps) {
    super(props);
    const params = getParams<{ jobNumber: string; jobDescription: string, bidItemsPage: string}>(this.props.location);
    const pageFromUrl = params.bidItemsPage;

    this.state = {
      bidItemData: [],
      loading: false,
      changeDetected: false,
      globalFilter: null,
      bidItemFiltered: [],
      globalSearch: '',
      /**
       *  on pagination, the rows prop sets how many items we will have on the page, first1 is the item index from where it starts to show on the current page
       *  if we are on the second page, the index of the first item will pe 20, because on the first page we show from item 0 to 19, and so on
       */
      pagination: {
        first1: (parseInt(pageFromUrl) != null && parseInt(pageFromUrl) > 1) ? ((parseInt(pageFromUrl) - 1) * 20) : 0,
        rows: 20,
        currentPage: parseInt(pageFromUrl) != null ? parseInt(pageFromUrl) : 1,
      },
      paginationHistory: null,
      isSavedBidItem : false,
      inputInitialValue: '',
      isFiltered: false,
      filters: {},
      username: '',
      taskCodesWarning: []
    };

    this.bidItemsService = new BidItemsService();
    this.jobDataService = new JobDataService();
    this.executiveSummaryService = new ExecutiveSummaryService();

    this.bidItemStructure = bidItemDictionary;

    this.actionTemplate = this.actionTemplate.bind(this);
    this.onEditorSubmit = this.onEditorSubmit.bind(this);
    this.handleRemCostFilter = this.handleRemCostFilter.bind(this);
    this.handleDeltaFilter = this.handleDeltaFilter.bind(this);
  }

  componentDidMount() {
    this.mountedComponent = true;
    this.setJobNumberAndJobDescription();
    window.scrollTo(0, 0);
  }

  componentWillUnmount(): void {
    this.mountedComponent = false;
  }

  componentDidUpdate(prevProps: BidItemsProps, prevState: BidItemsState) {
    if (prevProps.location.search !== this.props.location.search || (this.props.resetJobInProgress && !this.props.currentJobBidItems.length)) {
      this.setJobNumberAndJobDescription();
    }

    if ((prevProps.resetJobInProgress !== this.props.resetJobInProgress) || (this.props.resetJobInProgress && !this.state.loading)) {
      this.setState({loading: true});
    }

    if (!prevProps.currentJobBidItems.length && this.props.currentJobBidItems.length) {
      this.setInitialValues()
    }

    if(this.props.location !== prevProps.location) {
      const params = getParams<{ jobNumber: string; jobDescription: string, bidItemsPage: string}>(this.props.location);

      if(parseInt(params.bidItemsPage) !== this.state.pagination.currentPage) {
        this.updatePagination(parseInt(params.bidItemsPage));
      }
    }

    if (this.state.isSavedBidItem) {
      this.setInitialValues();
      this.setState({ isSavedBidItem: false });
    }

    if (!Object.is(prevState.filters, this.state.filters) || prevState.globalSearch !== this.state.globalSearch) {
      let isFiltered = false
      let filteredData = this.state.bidItemData

      for (const [key, value] of Object.entries(this.state.filters)) {
        if (value && filteredData.length) {
          isFiltered = true

          filteredData = this.filterZeroValuesColumns(filteredData, key)
        }
      }

      if ((isFiltered || this.state.globalSearch) && !this.state.paginationHistory) {
        this.setState({
          paginationHistory: this.state.pagination
        })
      }

      if (isFiltered) {
        this.setState({
          bidItemFiltered: filteredData,
          isFiltered: true
        })
      } else {
        this.setState({
          isFiltered: false
        })
      }

      this.state.globalSearch && this.setGlobalSearchBidItems(this.state.globalSearch, filteredData)

      if (!isFiltered && !this.state.globalSearch && this.state.paginationHistory) {
        const pagination = {
          first: this.state.paginationHistory.first1,
          rows: this.state.paginationHistory.rows,
          page: this.state.paginationHistory.currentPage,
        }
        this.onCustomPage(pagination)
        this.setState({
          paginationHistory: null
        })
      }
    }

    if (prevProps.jobNumber !== this.props.jobNumber) {
      this.setState({
        globalSearch: '',
        bidItemFiltered: [],
        isFiltered: false,
        filters: {}
      });
    }

    if (prevProps.lastUpdated !== this.props.lastUpdated) {
      this.setInitialValues();
    }

    if (prevProps.isJobDataLoading !== this.props.isJobDataLoading) {
      this.setState({ loading: this.props.isJobDataLoading });
    }
  }

  filterZeroValuesColumns(bidItems: BidItemToBeSaved[], column: string) {
    const filteredItems = bidItems.filter((bi: any) => Number(parseFloatRemoveComma(bi[column]).toFixed(2)) !== 0)

    return filteredItems;
  }

  updatePagination(bidItemsPage: number) {
    this.setState({
      pagination: {
        rows: 20,
        first1: (bidItemsPage != null && bidItemsPage > 1) ? ((bidItemsPage - 1) * 20) : 0,
        currentPage: bidItemsPage
      }
    });
  }

  setJobNumberAndJobDescription() {
    if (this.mountedComponent) {
      let jobNumber: string;
      let jobDescription: string;

      const params = getParams<{ jobNumber: string; jobDescription: string, bidItemsPage: string}>(this.props.location);

      if (this.props.jobNumber !== "0") {
        jobNumber = this.props.jobNumber;
      } else {
        // the user copy/pasted the URL or changed the jobNumber from URL
        jobNumber = params.jobNumber;
      }

      if (this.props.jobDescription) {
        jobDescription = this.props.jobDescription
      } else {
        jobDescription = params.jobDescription;
      }

      if (!this.props.jobNumber || !this.props.jobDescription) {
        this.props.setCurrentJobNumberAndDescription({
          JobNumber: jobNumber,
          JobDescription: jobDescription
        });
      }
      if (!this.props.currentJobBidItems.length || (this.props.resetJobInProgress && !this.props.currentJobBidItems.length)) {
        this.getBidItemsFromServer(jobNumber).then(() => {
          this.setInitialValues();
        });
      } else {
        this.setInitialValues();
      }
    }
  }

  checkTaskcodesWithoutBidItem(data: any){
    let bidItemNumber = []
    let taskCodesNotIncluded = []
    let taskCodesIncluded = []

    for(let i = 0; i < data.BidItems.length; i++){
      bidItemNumber.push(data.BidItems[i].BidItem)
    }
    for(let a = 0; a < data.TaskCodes.length; a++){
      const hardcodedBidItems = [777, 830, 850, 860, 861, 870, 880, 888, 890, 920, 940, 941, 950, 960, 999]
      if(!bidItemNumber.includes(data.TaskCodes[a].BidItem) && !hardcodedBidItems.includes(parseInt(data.TaskCodes[a].BidItem))){
        taskCodesNotIncluded.push(data.TaskCodes[a])
      }else{
        taskCodesIncluded.push(data.TaskCodes[a])
      }
    }
    this.setFlagFinalCost(taskCodesIncluded)
    this.props.setTaskcodesWithoutBidItem(taskCodesNotIncluded)
  }

  setFlagFinalCost(dataTaskcodesincluded: any){
    
    const taskCode = [...JSON.parse(JSON.stringify(dataTaskcodesincluded))]
    const taskCodesFlags = []
    
    
    for(let i = 0; i < taskCode.length; i++){
      let JTDTotalCost = parseInt(parseFloatRemoveComma(taskCode[i].JTDTotalCost).toFixed(2));
      let FinalCost = parseInt(parseFloatRemoveComma(taskCode[i].FinalCost).toFixed(2));
      let BudgetCost = parseInt(parseFloatRemoveComma(taskCode[i].BudgetCost).toFixed(2));
      let sumCCOs = 0;
      let finalCostCCO = 0;


    if (JTDTotalCost > FinalCost && BudgetCost !== FinalCost) {
      if(taskCode[i].CCOs != null){
        if(taskCode[i].CCOs.length > 0){
          for(let a = 0; a < taskCode.length; a++){
            if(taskCode[i].CCOs[a]?.AssignedCost){
              sumCCOs += parseInt(taskCode[i].CCOs[a].AssignedCost)
            }
          }
          if(parseFloatRemoveComma(taskCode[i].FinalQuantity) > 0){
            finalCostCCO = parseFloatRemoveComma(taskCode[i].FinalQuantity) * parseFloatRemoveComma(taskCode[i].FinalUnitCost);
          }else{
            finalCostCCO = parseFloatRemoveComma(taskCode[i].RemainingTotalCost) + parseFloatRemoveComma(taskCode[i].JTDTotalCost);
          }

          if(JTDTotalCost > finalCostCCO){
            taskCodesFlags.push(taskCode[i])
          }
      }
      }else{
        taskCodesFlags.push(taskCode[i]) 
      }
      }
    }
    this.props.setTaskcodesWithFlagsFC(taskCodesFlags)
  }

  async getBidItemsFromServer(jobNumber: string) {
    if(jobNumber == null) return;
    
    this.props.setResetJobInProgress({resetJobInProgress: false});
    
    this.setState({ loading: true });
    this.props.setChangeJobInProgress({changeJobInProgress: true});

    const [error, response] = await this.jobDataService.getJobData(jobNumber);

    if (error != null) {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Bid Items Error', detail: (error as AxiosError).response?.data.message?.toString(), life: 5000 });
    }
    if (response != null && this.mountedComponent) {
      this.props.setJobsMenuBidItemsAndTaskCodes({
        data: response.data,
        JobNumber: jobNumber
      });
      this.props.maintainAllLinkedTaskCodesToBidItems();

      this.checkTaskcodesWithoutBidItem(response.data)
    }

    this.setState({ loading: false });
    this.props.setChangeJobInProgress({ changeJobInProgress: false });
   
  }

  async setInitialValues() {
    let updatedBidItems = [...JSON.parse(JSON.stringify(this.props.currentJobBidItems))];
    let taskCodes = [...JSON.parse(JSON.stringify(this.props.currentJobTaskCodes))];
    BidItemCalculations.setBidItemFinalCostCCOs(updatedBidItems, taskCodes);
    BidItemCalculations.setBidItemInitialValues(updatedBidItems, taskCodes, true);
    
    for (let i = 0; i < this.props.currentJobBidItems.length; i++) {
      if (this.props.currentJobBidItems[i].FinalRevenue === 0) {
        updatedBidItems[i].FinalRevenue = 0;
      }
    }

    BidItemCalculations.calculateBidItemDetailsFinalCostField(updatedBidItems);
    BidItemCalculations.setBidItemFinalRevenueHardcodeBidItems(updatedBidItems);
    BidItemCalculations.setBidItemGainLossHardcodeBidItems(updatedBidItems);
    BidItemCalculations.setBidItemPrevGLandDelta(updatedBidItems);

    this.setState({ 
      bidItemData: updatedBidItems.map(el => el.ChgNumber === "000" ? { ...el, BidItemDisplay: el.BidItem } : { ...el, BidItemDisplay: "-" }),
    });

    await this.getUsername()
    this.updateExecutiveSummaryNewData(this.props.currentJobTaskCodes)
    
    }


  //#region "OnValueChanged and OnSubmit"
  editor(props: any, key: string) {
    return <InputText type="text" value={props.rowData[key]} onFocus={this.handleFocus} onChange={(e) => this.onEditorValueChange(props, e.target)} />;
  }

  onEditorCancel(props: any) {
    let updatedBidItems: BidItemToBeSaved[] = BidItemCalculations.calculateBidItemFields(props, this.state.inputInitialValue);

    this.setState({
      bidItemData: updatedBidItems,
      inputInitialValue: ''
    });
  }

  onEditorValueChange(props: any, input: any) {
    this.setState({ changeDetected: true });
    let myInput: string = "";

    myInput = numbersAndDotOnly(input.value);

    let updatedBidItems: BidItemToBeSaved[] = BidItemCalculations.calculateBidItemFields(props, myInput);
    this.setState({ bidItemData: updatedBidItems });    
  }

  onEditorSubmit(props: any) {
    if (this.state.changeDetected) {
      if (props.field === "FinalRevenue" || props.field === "FinalQuantity" || props.field === "QtyAdjustment") {
        this.saveBidItem(props, true);
      } else {
        this.saveBidItem(props, false);
      }

      this.setState({
        changeDetected: false,
        inputInitialValue: ''
      });
    }
  }


  async updateExecutiveSummaryNewData(taskCodes: any){
    try {
      const _date = getMonthYearForExecutiveSummary();
      this.props.setLoadingExecutiveSummary({ setLoading: true });
    
      const [error, response] = await this.executiveSummaryService.getExecutiveSummary(this.props.jobNumber.toString(), parseInt(_date.month), parseInt(_date.year));
    
      if (taskCodes && response?.data && response.data.length > 0) {
        for (let i = 0; i < taskCodes.length; i++) {
          let updateExecutive = false;
          const dateNow = new Date(response.data[0].DateChanges);
          const datedb = new Date(taskCodes[i].LastUpdated);
    
          if (dateNow < datedb) {
            updateExecutive = true;
          }
    
          if (updateExecutive) {
            const [updateExecutiveSummaryError, updateExecutiveSummaryResponse] = await this.executiveSummaryService.updateExecutiveSummary(this.props.jobNumber.toString(), parseInt(_date.month), parseInt(_date.year), "Forecast System");
    
            if (updateExecutiveSummaryResponse) {
              this.props.setLoadingExecutiveSummary({ setLoading: false });
              return this.toastTR?.current?.show({ severity: 'success', summary: 'Update Executive Summary', detail: "Executive Summary Save successfully! ", life: 5000 });
            } else if (updateExecutiveSummaryError) {
              this.props.setLoadingExecutiveSummary({ setLoading: false });
              return this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: updateExecutiveSummaryError, life: 5000 })
            }
          }
        }
      }
    } catch (error) {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: error, life: 5000 })
    } finally {
      this.props.setLoadingExecutiveSummary({ setLoading: false });
    }    
  }
  async getUsername() {
    //Setting username for Submit flag
			let url: string = window.location.href;
			let region: string;

			if (url.includes('qa')) {
  			region = "us-west-1";
			} else {
  			region = "us-west-2"	
			}
      
      let accessToken = this.props.accessToken;
      
      if (accessToken) {
				AWS.config.update({ region: region });
				let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
				let params = {
					AccessToken: accessToken
				};
				await cognitoidentityserviceprovider.getUser(params, (err, data) => {
					if (err) {
            console.error(err, err.stack);
            this.props.history.push(`/logout`);
					} else {
            this.setState({ username: data.Username },()=>this.props.setUsername(this.state.username))
					}
				});
    }
 }

  async saveBidItem(props: any, HasEditedFields: boolean) {
    const { rowIndex: index, field } = props;
    // @ts-ignore
    let myValue: string = this.state.bidItemData[index][field];
    let updatedBidItems: any[] = [...this.state.bidItemData];

    if (isNaN(parseFloat(myValue))) {
      myValue = "0.00";
    } else if (myValue.toString().slice(myValue.length - 1) === ".") {
      myValue += "0";
    }

    updatedBidItems[index][field] = toLocaleStringUS(parseFloatRemoveComma(myValue));
    if (HasEditedFields) {
      updatedBidItems[index].HasEditedFields = true;
    }

    this.setState({ bidItemData: updatedBidItems });

    let bidItemToBeSaved = BidItemCalculations.prepareBidItemForDB(updatedBidItems[index], this.props.jobNumber.toString(), this.props.jobDescription);


    this.props.bidItemSaved({
      BidItemToBeSaved: bidItemToBeSaved,
      JobNumber: this.props.jobNumber,
      JobDescription: this.props.jobDescription
    });

    try {
      this.props.setLoadingExecutiveSummary({ setLoading: true });
    
      const saveBidItemResponse = await this.bidItemsService.saveBidItemToDB([bidItemToBeSaved]);
    
      if (saveBidItemResponse != null) {
        const _date = getMonthYearForExecutiveSummary();
        const [updateExecutiveSummaryError, updateExecutiveSummaryResponse] = await this.executiveSummaryService.updateExecutiveSummary(this.props.jobNumber.toString(), parseInt(_date.month), parseInt(_date.year), this.state.username);
    
        if (updateExecutiveSummaryResponse) {
          this.toastTR?.current?.show({ severity: 'success', summary: 'Update Executive Summary', detail: "Executive Summary Save successfully! ", life: 5000 });
        } else {
          this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: updateExecutiveSummaryError, life: 5000 });
        }
      }
    } catch (error) {
      this.toastTR?.current?.show({ severity: 'error', summary: 'Task Codes Assignment Error', detail: error, life: 5000 });
    } finally {
      this.props.setLoadingExecutiveSummary({ setLoading: false });
    }
    
    
    // We reset here the Current Bid Item so that it can be re-initialized when you arrive on the Bid Details
    this.props.setCurrentBidItems();
    
    this.setState({ isSavedBidItem: true });
  }
  //#endregion

  actionTemplate(rowData: any, column: any) {
    return <Button type="button" icon="pi-md-search" className="p-button-success" style={{ marginRight: '.5em' }} onClick={() => {
      this.props.setCurrentBidItemNumber(rowData["BidItem"]);
      this.props.setCurrentBidItemDisplay(rowData["BidItemDisplay"]);
      this.props.setCurrentChangeNumber({
        ChgNumber: rowData["ChgNumber"],
        ChgNumberDesc: rowData["ChgOrderDesc"]
      });
      this.props.location.pathname = "/bidDetails";
    }} />
  } 

  onCustomPage(event: any) {
    this.setState({
      pagination: {
        first1: event.first,
        rows: event.rows,
        currentPage: event.page + 1
      }
    });

    this.props.history.push(`/job?jobNumber=${this.props.jobNumber}&jobDescription=${this.props.jobDescription}&bidItemsPage=${(event.page + 1)}`);
  }

  getFirstPageWhereCCOStarts = () => {
    let res;
    let firstPage;
    
    if (this.state.globalSearch.length > 0) {
    res = Math.trunc((this.state.bidItemFiltered.findIndex((bi: { ChgNumber: string; }) => bi.ChgNumber !== "000") + 20 ) / 20);
    firstPage = Math.trunc((this.state.bidItemFiltered.findIndex((bi: { ChgNumber: string; }) => bi.ChgNumber !== "000") ) / 20) * 20;
    } else {
    res = Math.trunc((this.props.currentJobBidItems.findIndex(bi => bi.ChgNumber !== "000") + 20 ) / 20);
    firstPage = Math.trunc((this.props.currentJobBidItems.findIndex(bi => bi.ChgNumber !== "000") ) / 20) * 20; 
    }
    
    if (res !== 0) {
      this.setState({
        pagination: {
          first1: firstPage,
          rows: 20,
          currentPage: res
        }
      });
  
      this.props.history.push(`/job?jobNumber=${this.props.jobNumber}&jobDescription=${this.props.jobDescription}&bidItemsPage=${(res)}`);
    }
  }  

  downloadExcel() {
    
    let bidItems: BidItemToBeSaved[] = this.state.bidItemData;
    const taskCodes = this.props.currentJobTaskCodes;
    const filePath = `Forecast-jobnumber${this.props.jobNumber}-revenue-and-costs.xlsx`;

    for(let i = 0; i < bidItems.length; i++){
        bidItems[i].RemCost = parseFloatRemoveComma(bidItems[i].RemCost)
    }

    exportBidItemsAndTaskCodes({ bidItems, taskCodes }, filePath);
  }

  handleChange: any = (event: any) => {
    this.setState({ globalSearch: (event.target as HTMLTextAreaElement).value });
  }

  setGlobalSearchBidItems  = (search: any, filteredData?: any[]) => {
    let currentBidItems: BidItemToBeSaved[] = filteredData ?? this.state.bidItemData;
    
    let globalSearchbidItem = currentBidItems.filter((bidItem) => (  
      (bidItem?.BidItem).includes(search)
      || (bidItem?.BidItemDescription).toLowerCase().includes(search)
      || parseFloatRemoveComma(bidItem?.Amount).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.FinalCost).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.FinalQuantity).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.FinalRevenue).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.GainLoss).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.UnitPrice).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.JTDQty).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.Changes).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.PreviousFinalCost).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.JTDQty).toFixed(2).includes(search)
      || parseFloatRemoveComma(bidItem?.RemCost).toFixed(2).includes(search)
      )
    )    
    
    this.setState({ bidItemFiltered: [...globalSearchbidItem]});
  }

  handleRemCostFilter() {
    this.setState({
      filters: {
        ...this.state.filters,
        RemCost: !this.state.filters.RemCost
      }
    });
  }

  handleDeltaFilter() {
    this.setState({
      filters: {
        ...this.state.filters,
        Changes: !this.state.filters.Changes
      }
    });
  }


  render() {
    const remCostHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={this.handleRemCostFilter} checked={this.state.filters.RemCost} />
        </span>
      </div>
    )

    const deltaHeader = (value: any) => (
      <div className='col-header-with-filter'>
        <span>{value}</span>
        <span>
          <Checkbox onChange={this.handleDeltaFilter} checked={this.state.filters.Changes} />
        </span>
      </div>
    )

    let bidItemDynamicColumns = [Object.entries(this.bidItemStructure).map(([key, value]) => {
      switch (key) {
        case "BidItem":
          return <Column headerStyle={{ textAlign: 'left', width: '3em' }}
            excludeGlobalFilter={true}
            style={{ textAlign: 'left', width: '3em', fontWeight: "900" }} />;
        case "BidItemDisplay":
          return <Column key={key} 
          body={(rowData: any) => (rowData[key] === "950")
          ? "950/960"
          : rowData[key]}
         field={key} header={value} headerStyle={{ textAlign: 'left', width: '3em' }}
            style={{ textAlign: 'left', width: '3em', fontWeight: "900" }} />;
        case "BidItemDescription":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'left', width: '10em' }}
            style={{ textAlign: 'left', width: '10em', fontWeight: "900" }} />;
        case "ChgOrderDesc":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'left', width: '5em' }}
            style={{ textAlign: 'left', width: '5em', fontWeight: "900" }} />;
        case "UM":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key])) ? rowData[key] : toLocaleStringUS(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "QTYBilled":
        case "UnitPrice":
        case "Amount":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FFF9AB' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "QtyAdjustment":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#D2FACF' }}
            editor={
            (props) => this.IsThisHardcodedBidItemAllowFinalRevenueEdit(props.rowData) || this.IsThisPCItem(props.rowData)
              ? displayTwoDigits(props.rowData[key]) === "0.00"
              ? props.rowData[key]
              : displayTwoDigits(props.rowData[key])
              : this.editor(props, key)
            }
            onEditorCancel={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItem(props.rowData)
              ? undefined
              : this.onEditorCancel(props)}
            onEditorSubmit={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItem(props.rowData)
              ? undefined
              : this.onEditorSubmit(props)}
            body={(rowData: any) => this.IsThisHardcodedBidItem(rowData) || this.IsThisPCItem(rowData)
              ? displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])} 
            style={{ textAlign: 'center', width: '3em'}}
          />;
        case "JTDQty":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FAD8B1' }}
            body={(rowData: any) => this.IsThisHardcodedBidItem(rowData)
              ? displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])} 
            style={{ textAlign: 'center', width: '3em' }} />;
        case "FinalQuantity":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FAD8B1' }}
            editor={
            (props) => this.IsThisHardcodedBidItemAllowFinalRevenueEdit(props.rowData) || this.IsThisPCItem(props.rowData)
              ? displayTwoDigits(props.rowData[key]) === "0.00"
              ? props.rowData[key]
              : displayTwoDigits(props.rowData[key])
              : this.editor(props, key)
            }
            onEditorCancel={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItem(props.rowData)
              ? undefined
              : this.onEditorCancel(props)}
            onEditorSubmit={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItem(props.rowData)
              ? undefined
              : this.onEditorSubmit(props)}
            body={(rowData: any) => this.IsThisHardcodedBidItem(rowData)
              ? displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em'}} />;
        case "FinalRevenue":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#BADFF5' }}
            editor={
            (props) => this.IsThisHardcodedBidItemAllowFinalRevenueEdit(props.rowData) || this.IsThisPCItem(props.rowData)
              ? displayTwoDigits(props.rowData[key]) === "0.00"
              ? props.rowData[key]
              : displayTwoDigits(props.rowData[key])
              : this.editor(props, key)
              }
            onEditorCancel={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItemAllowFinalRevenueEdit(props.rowData)
              ? undefined
              : this.onEditorCancel(props)}
            onEditorSubmit={(props) => this.IsThisPCItem(props.rowData) || this.IsThisHardcodedBidItemAllowFinalRevenueEdit(props.rowData)
              ? undefined
              : this.onEditorSubmit(props)}
            body={(rowData: any) => this.IsThisHardcodedBidItemAllowFinalRevenueEdit(rowData)
              ? displayTwoDigits(rowData[key]) === "0.00" || isNaN(parseFloat(rowData[key])) ? "-" : displayTwoDigits(rowData[key])
              : isNaN(parseFloat(rowData[key]))
                ? rowData[key]
                : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em'}} />;
        case "FinalCost":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#BADFF5' }}
            body={(rowData: any) => displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "RemCost":
          return <Column key={key} field={key} header={remCostHeader(value)} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#BADFF5' }}
            body={(rowData: any) => displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "GainLoss":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#BADFF5' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "PreviousFinalCost":
          return <Column key={key} field={key} header={value} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FFA3E0' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "Changes":
          return <Column key={key} field={key} header={deltaHeader(value)} headerStyle={{ textAlign: 'center', width: '3em', backgroundColor: '#FFA3E0' }}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
        case "HasEditedFields":
        case "BidComments":
          return null
        default:
          return <Column key={key} field={key} header={value}
            body={(rowData: any) => isNaN(parseFloat(rowData[key]))
              ? rowData[key]
              : displayTwoDigits(rowData[key]) === "0.00" ? "-" : displayTwoDigits(rowData[key])}
            style={{ textAlign: 'center', width: '3em' }} />;
      }
    })];

    let header = (
      <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'flexWrap': 'wrap' }}>
        Bid Items
        <div className="p-datatable-globalfilter-container">
          <InputText type="search" onInput={this.handleChange} placeholder="Global Search" sizes="50" value={this.state.globalSearch} />
        </div>
      </div>
    );

    return (
      <div>
        <Growl ref={this.toastTR} />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="biditems-table-title">
                  <div className="biditems-title-link">
                    <h1>Bid Items</h1> 
                    {
                      this.props.currentJobBidItems.findIndex(bi => bi.ChgNumber !== "000")
                        ? <div 
                          className="go_to_cco" 
                          onClick={() => this.getFirstPageWhereCCOStarts()}>
                            Go to the first page where CCOs start
                          </div>
                        : null
                    }
                  </div>
                  <span className="create_excel" onClick={() => this.downloadExcel()} title="Download report sheet">
                      {!this.state.loading && <i className='pi pi-download'></i>}
                  </span>
                </div>
                <DataTable
                  value={(this.state.isFiltered || this.state.globalSearch)
                    ? this.state.bidItemFiltered
                    : this.state.bidItemData
                  }
                  header={header}
                  paginator={true}
                  rows={20}
                  globalFilter={this.state.globalFilter}
                  rowClassName={this.rowClass}
                  autoLayout={true}
                  loading={this.state.loading}
                  loadingIcon={'pi-md-refresh'}
                  first={this.state.pagination.first1 as number}
                  onPage={(e) => this.onCustomPage(e)}
                  className="bid-items-table"
                >
                  <Column header={""} body={this.actionTemplate} style={{ textAlign: 'center', width: '3em' }} />
                  {bidItemDynamicColumns}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    jobNumber: state.app.currentJobNumber,
    jobDescription: state.app.currentJobDescription,
    currentJobBidItems: state.app.jobsMenuItems?.find(jobMenuItem => jobMenuItem.JobNumber === state.app.currentJobNumber)?.BidItems || [],
    currentJobTaskCodes: state.app.jobsMenuItems?.find(jobMenuItem => jobMenuItem.JobNumber === state.app.currentJobNumber)?.TaskCodes || [],
    resetJobInProgress: state.app.resetJobInProgress,
    lastUpdated: state.app.lastUpdated,
    isJobDataLoading: state.app.isJobDataLoading,
    accessToken: state.app.auth.token,
  };
}

// TODO: research if you can replace this "any"
function matchDispatchToProps(dispatch: any) {
  return bindActionCreators({
    setCurrentBidItemNumber: setCurrentBidItemNumber,
    setCurrentJobNumberAndDescription: setCurrentJobNumberAndDescription,
    setCurrentChangeNumber: setCurrentChangeNumber,
    setCurrentBidItemDisplay: setCurrentBidItemDisplay,
    setCurrentBidItems: setCurrentBidItems,
    setJobsMenuBidItemsAndTaskCodes: setJobsMenuBidItemsAndTaskCodes,
    bidItemSaved: bidItemSaved,
    maintainAllLinkedTaskCodesToBidItems: maintainAllLinkedTaskCodesToBidItems,
    setChangeJobInProgress: setChangeJobInProgress,
    setExecutiveSummary: setExecutiveSummary, 
    setResetJobInProgress: setResetJobInProgress,
    setLoadingExecutiveSummary: setLoadingExecutiveSummary,
    setUsername: setUsername,
    setPrevGLandDeltaCCOs: setPrevGLandDeltaCCOs,
    setTaskcodesWithoutBidItem: setTaskcodesWithoutBidItem,
    setTaskcodesWithFlagsFC: setTaskcodesWithFlagsFC
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(BidItems);